import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"

const Footer = () => (
  <footer className="site-footer">
    <div className="container position-relative">
      <div className="row justify-content-between">
        <div className="col-lg-4">
          <h5 className="mb-4">About</h5>
          <p className="mb-0">
            Devenny Construction builds high quality homes that our clients love
            to live in. Delivering a superior service that ensures peace of
            mind, James Devenny, owner/ builder, prides himself in going the
            extra mile to ensure a beautiful finish on every job.
          </p>
        </div>
        <div className="col-lg-3 mt-4 mb-3 m-lg-0">
          <h5 className="mb-4">Contact</h5>
          <ul className="fa-ul">
            <li className="pb-2">
              <span className="fa-li">
                <i className="fas fa-phone"></i>
              </span>
              <a href="tel:0220821972">022 082 1972</a>
            </li>
            <li className="pb-2">
              <span className="fa-li">
                <i className="fas fa-envelope"></i>
              </span>
              <a href="mailto:james@devennyconstruction.co.nz">
                james@devennyconstruction.co.nz
              </a>
            </li>
            <li>
              <span className="fa-li">
                <i className="fas fa-map-marker-alt"></i>
              </span>
              7 Coradine Street, Masterton
            </li>
          </ul>
        </div>
        <div className="col-lg-3">
          <div className="footer__menu">
            <h5 className="mb-4">Services</h5>
            <Nav className="flex-column">
              <Link
                activeClassName="active"
                className="nav-link pl-0 pt-0"
                to="/new-builds/"
              >
                New Builds
              </Link>
              <Link
                activeClassName="active"
                className="nav-link pl-0 pt-0"
                to="/renovations/"
              >
                Renovations
              </Link>
              <Link
                activeClassName="active"
                className="nav-link pl-0 pt-0"
                to="/home-improvements/"
              >
                Home Improvements
              </Link>
            </Nav>
          </div>
        </div>
      </div>

      <p className="footer__copyright text-center  mb-0">
        Website by{" "}
        <a target="_blank" rel="noreferrer" href="https://sixvees.co.nz">
          Sixvees
        </a>
        .
      </p>
    </div>
  </footer>
)

export default Footer
