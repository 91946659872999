import * as React from "react"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
  <header>
    <div className="container">
      <Navbar expand="lg" className="px-0">
        <Link className="navbar-brand" to="/">
          <StaticImage
            src="../images/logo.jpg"
            width={200}
            alt="Devenny Construction logo"
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Link to="/" className="nav-link" activeClassName="active">
              Home
            </Link>
            <Link
              className="nav-link"
              activeClassName="active"
              to="/new-builds/"
            >
              New Builds
            </Link>
            <Link
              className="nav-link"
              activeClassName="active"
              to="/renovations/"
            >
              Renovations
            </Link>
            <Link
              className="nav-link"
              activeClassName="active"
              to="/home-improvements/"
            >
              Home Improvements
            </Link>
            <Link to="/contact/" className="btn btn-primary text-uppercase">
              Contact
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  </header>
)

export default Header
