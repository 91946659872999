import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const WhyChooseUs = () => {
  return (
    <section className="bg-dark-brick  text-white">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <h3 className="mb-5">Why Choose Us</h3>
            <ul className="fa-ul pl-4">
              <li className="d-flex align-items-center mb-4 font-weight-bold">
                <span className="fa-li fa-2x">
                  <i className="fas fa-check-circle"></i>
                </span>
                Licenced Building Professional
              </li>
              <li className="d-flex align-items-center mb-4 font-weight-bold">
                <span className="fa-li fa-2x">
                  <i className="fas fa-check-circle"></i>
                </span>
                Passionate, highly skilled team
              </li>
              <li className="d-flex align-items-center mb-4 font-weight-bold">
                <span className="fa-li fa-2x">
                  <i className="fas fa-check-circle"></i>
                </span>
                Reliable service, we take pride in our work
              </li>
              <li className="d-flex align-items-center mb-4 font-weight-bold">
                <span className="fa-li fa-2x">
                  <i className="fas fa-check-circle"></i>
                </span>
                Experienced in new builds and renovation
              </li>

              <li className="d-flex align-items-center mb-4 font-weight-bold">
                <span className="fa-li fa-2x">
                  <i className="fas fa-check-circle"></i>
                </span>
                Free consultation and price estimate
              </li>
            </ul>
            <StaticImage
              height={124}
              className="lbp-image mt-3"
              src="../images/lbp-roundel-white-png.png"
              alt="Licensed Building Practitioner"
            />
            <StaticImage
              height={124}
              className="lbp-image mt-3 ml-5"
              src="../images/NZCB-Logo-FINAL_RGB.png"
              alt="New Zealand Certified Builders Association"
            />
          </div>
          <div className="mt-5 mt-lg-0 col-lg-6">
            <h3 className="mb-5">Get Free Quote</h3>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="Contact"
              action="/thank-you/"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="Contact" />
              <div className="form-group">
                <div className="form-group">
                  <label htmlFor="name" className="sr-only">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    name="name"
                    required
                  />
                </div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone" className="sr-only">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  className="form-control"
                  rows="5"
                  placeholder="Message"
                  name="message"
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
